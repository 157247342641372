import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":""}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"md":"6","sm":"12"}},[_c(VContainer,[_c(VSelect,{attrs:{"items":_vm.tiposInstrumentos,"item-text":"nombre","item-value":"tipo","label":"Tipo:","outlined":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.getInstrumento()}},model:{value:(_vm.tipoSeleccionado),callback:function ($$v) {_vm.tipoSeleccionado=$$v},expression:"tipoSeleccionado"}})],1)],1),_c(VCol,{attrs:{"md":"6","sm":"12"}},[_c(VContainer,[_c('div',{staticClass:"d-flex"},[_c(VTextField,{attrs:{"label":"Buscar","append-icon":"mdi-magnify","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VCardText,[(!_vm.loading && !_vm.instrumentos.length)?_c('span',[_vm._v("No se cuenta con instrumentos compartidos.")]):(_vm.loading)?_c('loadingInstrumentosComponent'):_c('listadoInstrumentosCompartidosComponent',{attrs:{"tipo":_vm.tipoSeleccionado,"instrumentos":_vm.instrumentosFiltrados,"areas":_vm.areasInstrumentos},on:{"instrumentoClonado":_vm.instrumentoClonado}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }