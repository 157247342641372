<template>
  <v-list>
    <v-list-item v-for="instrumento in instrumentos" :key="instrumento._id">
      <v-list-item-avatar>
        <v-avatar color="primary">
          <v-icon v-if="tipo == 'listaCotejo'" dark>{{
            listaCotejoIcon
          }}</v-icon>
          <v-icon v-if="tipo == 'rubrica'" dark>{{ rubricaIcon }}</v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <router-link
            :to="`instrumentos/${tipo}/${instrumento._id}`"
            class="link"
            >{{ instrumento.nombre }}
          </router-link>
        </v-list-item-title>
        
        <v-card v-if="app == 'escuela' || app == 'empresa'" style="font-size: 10.5px; margin-bottom: 5px;" flat>Área: {{ instrumento.instrumentoArea[0].nombre }}</v-card>
        
        <v-list-item-subtitle>
          <instrumentoStatusComponent :status="instrumento.status" />
          <v-chip x-small class="ml-1">{{ getRolInstrumento(instrumento) }}</v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="habilitado">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(menuInstrumentoItem, index) in menuInstrumento"
              :key="index"
              @click="
                menuAction({
                  ...menuInstrumentoItem,
                  id: instrumento._id,
                })
              "
            >
              <v-list-item-title>
                <v-icon small class="mr-2">{{
                  menuInstrumentoItem.icon
                }}</v-icon>
                <span>{{ menuInstrumentoItem.text }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>

    <clonarInstrumentoComponent
      v-if="mostrarClonarInstrumento.mostrar"
      :mostrar="mostrarClonarInstrumento.mostrar"
      :instrumento="mostrarClonarInstrumento.instrumento"
      :areas="areas"
      :tipo="tipo"
      @cancelar="cerrarClonarInstrumento"
      @instrumentoClonado="instrumentoClonado"
    />

  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
import instrumentoStatusComponent from "./instrumentoStatus.vue";
import clonarInstrumentoComponent from "./clonarInstrumento.vue";

export default {
  name: "listadoInstrumentosCompartidos",
  props: {
    instrumentos: { type: Array, default: () => [] },
    tipo: { type: String, enum: ["listaCotejo", "rubrica"] },
    areas: { type: Array, default: () => [] },
  },
  components: {
    instrumentoStatusComponent,
    clonarInstrumentoComponent
  },
  computed: {
    ...mapGetters([
      "listaCotejoIcon",
      "rubricaIcon",
      "userId",
      "app",
    ]),
    habilitado() {
      let mostrar = false;
      if (this.app == 'escuela' || this.app == 'empresa') mostrar = false;
      if(this.app == 'campus') mostrar = true;
      return mostrar;
    }
  },
  data() {
    return {
      loading: false,
      mostrarClonarInstrumento: { mostrar: false, instrumento: null },
      menuInstrumento: [
        {
          text: "Copiar a mis instrumentos",
          action: "clonarInstrumento",
          icon: "mdi-content-copy",
        },
      ],
    };
  },
  methods: {
    getRolInstrumento(instrumento) {
        const esColaborador = instrumento.colaboradores.find((i) => i == this.userId);
        return esColaborador ? "Colaborador" : "Compartido";
    },
    menuAction(data) {
      switch (data.action) {
        case "clonarInstrumento":
          this.clonarInstrumento(data.id);
          break;
      }
    },
    clonarInstrumento(idInstrumento) {
      const indexInstrumento = this.instrumentos.findIndex((x) => x._id === idInstrumento);
      this.mostrarClonarInstrumento.instrumento = this.instrumentos[indexInstrumento];
      this.mostrarClonarInstrumento.mostrar = true;
    },
    cerrarClonarInstrumento() {
      this.mostrarClonarInstrumento.mostrar = false;
      this.mostrarClonarInstrumento.instrumento = null;
    },
    instrumentoClonado(instrumento){
      this.$emit("instrumentoClonado", instrumento);
    }
  },
};
</script>