<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card>
      <v-toolbar
        dense
        color="primary"
        dark
        flat
        class="text-h6"
        v-if="tipo == 'listaCotejo'"
        >Crear lista cotejo</v-toolbar
      >
      <v-toolbar dense color="primary" dark flat class="text-h6" v-else
        >Crear rubrica</v-toolbar
      >
      <v-container style="margin-top: 15px">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="nombre"
              label="Nombre"
              :disabled="loading"
              outlined
              :error-messages="errors.nombre"
              dense
              @input="validarNombre()"
              @blur="validarNombre()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{
            'disable-events': errors.nombre.length > 0 || nombre.length === 0,
          }"
          @click="nuevoInstrumento()"
          >Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import {
  crearListaCotejoService,
  crearRubricaService,
} from "./instrumentos.service";

export default {
  name: "crearInstrumento",
  mixins: [validationMixin],
  props: {
    mostrar: { type: Boolean, default: false },
    idAreaInstrumento: { type: String, default: "" },
    tipo: { type: String, default: "" },
  },
  computed: {
    ...mapGetters(["apps", "appName", "sessionToken"]),
  },
  validations: {
    nombre: { required },
  },
  data() {
    return {
      loading: false,
      nombre: "",
      idArea: this.idAreaInstrumento,
      errors: {
        nombre: [],
      },
    };
  },
  methods: {
    validarNombre() {
      this.$v.nombre.$touch();
      const errors = [];
      if (this.$v.nombre.$dirty) {
        !this.$v.nombre.required && errors.push("Campo requerido.");
      }
      this.errors.nombre = errors;
    },
    async nuevoInstrumento() {
      try {
        this.loading = true;
        let serverResponse = null;
        const data = { nombre: this.nombre };

        if (this.tipo == "listaCotejo") {
          serverResponse = await crearListaCotejoService(this.idArea, data);
        }
        if (this.tipo == "rubrica") {
          serverResponse = await crearRubricaService(this.idArea, data);
        }
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if (this.tipo == "listaCotejo")
            this.$emit("listaCotejoGuardada", serverResponse.listaCotejo);
          if (this.tipo == "rubrica") {
            this.$emit("rubricaGuardada", serverResponse.rubrica);
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>