<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card :loading="loading">
      <v-toolbar dense color="primary" dark flat class="text-h6">
        <v-toolbar-title v-if="editarAreaInstrumento">Editar área</v-toolbar-title>
        <v-toolbar-title v-else>Crear área</v-toolbar-title>
      </v-toolbar>

      <v-container style="margin-top: 15px">
        <v-text-field
          v-model="nombreLocal"
          label="Nombre"
          :disabled="loading"
          :error-messages="errors.nombre"
          outlined
          dense
          @input="validarNombre()"
          @blur="validarNombre()"
        >
        </v-text-field>
      </v-container>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{
            'disable-events':
              errors.nombre.length > 0 || nombreLocal.length === 0,
          }"
          @click="nuevaArea()"
          >{{ textoBoton() }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import {
  crearAreaInstrumentoService,
  modificarAreaInstrumentoService,
} from "./areas.service";

export default {
  name: "crearAreaListaCotejo",
  mixins: [validationMixin],
  props: {
    mostrar: { type: Boolean, default: false },
    editarAreaInstrumento: { type: Boolean, default: false },
    area: { type: Object, default: () => null },
    tipo: { type: String, enum: ["listaCotejo", "rubrica"] },
  },
  computed: {
    ...mapGetters(["httpURL", "sessionToken"]),
  },
  validations: {
    nombreLocal: { required },
  },
  data() {
    return {
      loading: false,
      idArea: "",
      nombreLocal: "",
      errorMessage: null,
      errors: {
        nombre: [],
      },
    };
  },
  created() {
    if (this.editarAreaInstrumento) {
      this.idArea = this.area._id;
      this.nombreLocal = this.area.nombre;
    }
  },
  methods: {
    textoBoton(){
      if (this.editarAreaInstrumento)
        return 'Editar';
      return 'Crear';
    },
    validarNombre() {
      this.$v.nombreLocal.$touch();
      const errors = [];
      if (this.$v.nombreLocal.$dirty) {
        !this.$v.nombreLocal.required && errors.push("Campo requerido.");
      }
      this.errors.nombre = errors;
    },
    async nuevaArea() {
      try {
        let serverResponse = null;
        this.loading = true;
        const data = {
          nombre: this.nombreLocal,
        };

        if (this.editarAreaInstrumento) {
          serverResponse = await modificarAreaInstrumentoService(this.idArea,data, this.tipo);
        } else {
          serverResponse = await crearAreaInstrumentoService(data, this.tipo);
        }

        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("areaGuardada", serverResponse.areaInstrumentos);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

