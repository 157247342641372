<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card>
      <v-toolbar dense color="primary" dark flat class="text-h6"
        >Mover instrumento de área</v-toolbar
      >
      <v-container style="margin-top: 10px">
        <v-select
          v-model="idArea"
          label="Área"
          :items="areas"
          item-value="_id"
          item-text="nombre"
          :disabled="loading"
          outlined
          dense
          no-data-text="No hay áreas disponibles"
        ></v-select>
      </v-container>
      <v-spacer></v-spacer>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          @click="moverInstrumento()"
          >Mover
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  moverListaCotejoAreaService,
  moverRubricaAreaService,
} from "./instrumentos.service";

export default {
  name: "moverInstrumento",
  props: {
    mostrar: { type: Boolean, default: false },
    areas: { type: Array, default: () => [] },
    idInstrumento: { type: String, default: "" },
    tipo: { type: String, enum: ["listaCotejo", "rubrica"] },
  },
  computed: {
    ...mapGetters(["httpURL", "sessionToken", "colors"]),
  },
  data: () => ({
    loading: false,
    idArea: null,
  }),
  created() {
    if (this.areas.length > 0) this.idArea = this.areas[0]._id;
  },
  methods: {
    async moverInstrumento() {
      try {
        this.loading = true;
        let serverResponse = null;

        let data = { idInstrumento: this.idInstrumento, idArea: this.idArea };

        if (this.tipo == "listaCotejo")
          serverResponse = await moverListaCotejoAreaService(data);
        if (this.tipo == "rubrica")
          serverResponse = await moverRubricaAreaService(data);

        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if (this.tipo == "listaCotejo")
            this.$emit("instrumentoModificado", serverResponse.nuevaLista);
          if (this.tipo == "rubrica")
            this.$emit("instrumentoModificado", serverResponse.nuevaRubrica);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>