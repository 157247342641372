<template>
  <v-container>
    <v-skeleton-loader
      v-if="!escuelaTienePermiso"
      type="list-item-two-line@5"
    ></v-skeleton-loader>
    <v-card v-else>
      <v-tabs
        v-model="tab"
        centered
        icons-and-text
        background-color="secondary"
        dark
      >
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-tab v-if="permisoTabInstrumentos" href="#listas" to="#listas">
          Listas cotejo
          <v-icon>{{ listaCotejoIcon }}</v-icon>
        </v-tab>
        <v-tab v-if="permisoTabInstrumentos" href="#rubricas" to="#rubricas">
          Rúbricas
          <v-icon>{{ rubricaIcon }}</v-icon>
        </v-tab>
        <v-tab v-if="permisoTabCompartido" href="#compartidos" to="#compartidos">
          Compartido
          <v-icon>mdi-account-switch</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item  v-if="permisoTabInstrumentos" value="listas">
          <v-container>
            <instrumentosListaComponent
              tipo="listaCotejo"
              :nuevaListaCotejo="nuevaListaCotejo"
            />
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="permisoTabInstrumentos" value="rubricas">
          <v-container>
            <instrumentosRubricaComponent
              tipo="rubrica"
              :nuevaRubrica="nuevaRubrica"
            />
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="permisoTabCompartido" value="compartidos">
          <v-container>
            <instrumentosCompartidosComponent
              @instrumentoClonado="instrumentoClonado"
            />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import instrumentosListaComponent from "./instrumentosTabLista.vue";
import instrumentosRubricaComponent from "./instrumentosTabRubrica.vue";
import instrumentosCompartidosComponent from "./instrumentosTabCompartidos.vue";

import { permisoMenuEscuela } from "../../helpers/utilerias";

export default {
  name: "listadoInstrumentosView",
  components: {
    instrumentosListaComponent,
    instrumentosRubricaComponent,
    instrumentosCompartidosComponent,
  },
  computed: {
    ...mapGetters([
      "app",
      "listaCotejoIcon",
      "rubricaIcon",
      "tipoSesion",
      "role",
      "menu",
      "sessionToken",
    ]),
  },
  watch: {
    sessionToken() {
      if (!permisoMenuEscuela("Instrumentos")) this.$router.push("/dashboard");
      this.escuelaTienePermiso = true;
      this.setTabInstrumento();
    },
  },
  mounted() {
    if(this.sessionToken){
      if (!permisoMenuEscuela("Instrumentos")) this.$router.push("/dashboard");
      this.escuelaTienePermiso = true;
      this.setTabInstrumento();
    }
  },
  data() {
    return {
      loading: false,
      tab: "compartidos",
      permisoTabInstrumentos: false,
      permisoTabCompartido: false,
      nuevaListaCotejo: null,
      nuevaRubrica: null,
      escuelaTienePermiso: false,
    };
  },
  methods: {
    setTabInstrumento() {
      if ( this.app === 'campus' ) {
        if (this.tipoSesion.includes('profesor')) {
          this.tab = '#listas';
          this.permisoTabInstrumentos = true;
          this.permisoTabCompartido = true;
          return;
        }
      }
      if ( this.app === 'escuela' && this.role === 'admin' || this.role === 'master' ) {  
        this.tab = '#listas'; 
        this.permisoTabInstrumentos = true;
        return;
      }
      if ( this.app === 'empresa' && this.role === 'admin' || this.role === 'master') { 
        this.tab = '#listas'; 
        this.permisoTabInstrumentos = true;
        return; 
      }
      else {
        this.tab = '#compartidos';
        this.permisoTabInstrumentos = false;
        this.permisoTabCompartido = true;
        return;
      }
    },
    instrumentoClonado(instrumento, tipo) {
      if (tipo == "listaCotejo") this.nuevaListaCotejo = instrumento;
      if (tipo == "rubrica") this.nuevaRubrica = instrumento;
    },
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
</style>
