<template>
  <v-container>
    <v-card flat>
      <v-container>
        <v-row>
          <v-col md="6" sm="12">
            <v-container>
              <selectorAreasComponent
                :areas="areasRubricas"
                v-model="areaSeleccionada"
                :menu="role == 'admin' || role == 'master' || app == 'campus'"
                @change="getRubricas()"
                @agregarArea="abrirEditorAreaRubrica = true"
                @modificarArea="modificarAreaSeleccionada()"
              />
            </v-container>
          </v-col>
          <v-col md="6" sm="12">
            <v-container>
              <div class="d-flex">
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  append-icon="mdi-magnify"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      fab
                      small
                      style="margin-left: 10px"
                      :disabled="!areasRubricas.length || !areaSeleccionada"
                      @click="abrirCreacionInstrumento"
                      ><v-icon>{{ agregarContenidoIcon }}</v-icon></v-btn
                    >
                  </template>
                  <span>Agregar instrumento</span>
                </v-tooltip>
              </div>
            </v-container>
          </v-col>
        </v-row>
        <v-divider class="mx-3"></v-divider>
        <v-card-text>
          <span v-if="!loading && !rubricas.length"
            >No se han generado rúbricas</span
          >
          <loadingInstrumentosComponent v-else-if="loading" />
          <listadoInstrumentosComponent
            v-else
            :tipo="tipo"
            :instrumentos="rubricasFiltradas"
            :areas="areasRubricas"
            :area="areaSeleccionada"
            @instrumentoModificado="instrumentoModificado"
            @rubricaClonada="rubricaClonada"
          />
        </v-card-text>
      </v-container>

      <editarAreaInstrumentosComponent
        v-if="abrirEditorAreaRubrica"
        :mostrar="abrirEditorAreaRubrica"
        :editarAreaInstrumento="editarAreaRubrica"
        :area="areaParaModificar"
        :tipo="tipo"
        @cancelar="cancelarEditarArea"
        @areaGuardada="areaGuardada"
      />

      <crearInstrumentoDialog
        v-if="crearRubrica.mostrar"
        :mostrar="crearRubrica.mostrar"
        :tipo="tipo"
        :idAreaInstrumento="crearRubrica.id"
        @cancelar="cancelarCreacionInstrumento"
        @rubricaGuardada="rubricaGuardada"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import loadingInstrumentosComponent from "./loadingInstrumentos.vue";
import selectorAreasComponent from "../widgets/selectorAreas.vue";

import editarAreaInstrumentosComponent from "./areas/editarAreaInstrumentos.vue";

import crearInstrumentoDialog from "./crearInstrumento.vue";
import listadoInstrumentosComponent from "./listaInstrumentos.vue";

import { getRubricasByAreasService } from "./instrumentos.service";
import { getAreasInstrumentosService } from "./areas/areas.service";

export default {
  name: "rubricaLista",
  props: {
    tipo: { type: String, enum: ["listaCotejo", "rubrica"] },
    nuevaRubrica: { type: Object, default: () => {} }
  },
  components: {
    loadingInstrumentosComponent,
    selectorAreasComponent,
    crearInstrumentoDialog,
    listadoInstrumentosComponent,

    editarAreaInstrumentosComponent,
  },
  computed: {
    ...mapGetters([
      "rubricaIcon",
      "agregarContenidoIcon",
      "sessionToken",
      "role",
      "app",
    ]),
    rubricasFiltradas() {
      if (this.search)
        return this.rubricas.filter((r) =>
          r.nombre.toUpperCase().includes(this.search.toUpperCase())
        );
      return this.rubricas;
    },
  },
  watch: {
    sessionToken(value) {
      if (value) {
        this.getAreaRubricas();
      }
    },
    nuevaRubrica(value) {
      if (value) {
        this.getAreaRubricas();
      }
    }
  },
  async mounted() {
    if (this.sessionToken) {
      this.getAreaRubricas();
    }
  },
  data() {
    return {
      loading: true,
      search: "",
      rubricas: [],
      areasRubricas: [],
      areaSeleccionada: "",
      crearRubrica: {
        mostrar: false,
        id: null,
      },
      // Nuevo
      abrirEditorAreaRubrica: false,
      editarAreaRubrica: false,
      areaParaModificar: null,
    };
  },
  methods: {
    // Áreas Rubricas
    modificarAreaSeleccionada() {
      let area = this.areasRubricas.find((x) => x._id == this.areaSeleccionada);

      this.areaParaModificar = area;
      this.abrirEditorAreaRubrica = true;
      this.editarAreaRubrica = true;
    },
    areaGuardada(nuevaArea) {
      const areaExiste = this.areasRubricas.filter(
        (e) => e._id === nuevaArea._id
      )[0];

      if (areaExiste) {
        let index = this.areasRubricas.findIndex(
          (x) => x._id === nuevaArea._id
        );
        this.areasRubricas.splice(index, 1, nuevaArea);
      } else {
        this.areasRubricas.push(nuevaArea);
        this.areaSeleccionada = nuevaArea._id;
        this.getRubricas();
      }

      this.abrirEditorAreaRubrica = false;
      this.editarAreaRubrica = false;
      this.areaParaModificar = null;
    },
    cancelarEditarArea() {
      this.abrirEditorAreaRubrica = false;
      this.editarAreaRubrica = false;
      this.areaParaModificar = null;
    },
    async getAreaRubricas() {
      this.areasRubricas = [];
      this.areaSeleccionada = "";
      this.loading = true;
      try {
        const serverResponse = await getAreasInstrumentosService(this.tipo);
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if (serverResponse.areasInstrumentos.length != 0) {
            this.areasRubricas = serverResponse.areasInstrumentos;
            this.areaSeleccionada = serverResponse.areasInstrumentos[0]._id;
            await this.getRubricas();
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    // Rubricas
    abrirCreacionInstrumento() {
      this.crearRubrica.mostrar = true;
      this.crearRubrica.id = this.areaSeleccionada;
    },
    rubricaGuardada(rubrica) {
      this.rubricas.push(rubrica);
      this.crearRubrica.mostrar = false;
    },
    cancelarCreacionInstrumento() {
      this.crearRubrica.mostrar = false;
      this.crearRubrica.id = null;
    },
    async getRubricas() {
      try {
        this.rubricas = [];
        this.loading = true;

        const serverResponse = await getRubricasByAreasService(
          this.areaSeleccionada
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.rubricas = serverResponse.rubricas;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    instrumentoModificado(instrumento) {
      let indexInstrumento = this.rubricas.findIndex(
        (x) => x._id == instrumento._id
      );

      this.rubricas.splice(indexInstrumento, 1);
    },
    rubricaClonada() {
      this.getAreaRubricas();
    }
  },
};
</script>

<style>
</style>
