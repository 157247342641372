import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500px","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"primary","dark":"","flat":""}},[_vm._v("Mover instrumento de área")]),_c(VContainer,{staticStyle:{"margin-top":"10px"}},[_c(VSelect,{attrs:{"label":"Área","items":_vm.areas,"item-value":"_id","item-text":"nombre","disabled":_vm.loading,"outlined":"","dense":"","no-data-text":"No hay áreas disponibles"},model:{value:(_vm.idArea),callback:function ($$v) {_vm.idArea=$$v},expression:"idArea"}})],1),_c(VSpacer),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.moverInstrumento()}}},[_vm._v("Mover ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }