<template>
  <v-container>
    <v-card flat>
      <v-container>
        <v-row>
          <v-col md="6" sm="12">
            <v-container>
              <selectorAreasComponent
                :areas="areasListasCotejo"
                v-model="areaSeleccionada"
                :menu="esAdmin"
                @change="getListasCotejo"
                @agregarArea="abrirEditorAreaLista = true"
                @modificarArea="modificarAreaSeleccionada"
              />
            </v-container>
          </v-col>
          <v-col md="6" sm="12">
            <v-container>
              <div class="d-flex">
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  append-icon="mdi-magnify"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-tooltip v-if="esAdmin" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      fab
                      small
                      style="margin-left: 10px"
                      :disabled="!areasListasCotejo.length || !areaSeleccionada"
                      @click="abrirCreacionInstrumento"
                      ><v-icon>{{ agregarContenidoIcon }}</v-icon></v-btn
                    >
                  </template>
                  <span>Agregar instrumento</span>
                </v-tooltip>
              </div>
            </v-container>
          </v-col>
        </v-row>
        <!-- Nuevo -->
        <v-divider class="mx-3"></v-divider>
        <v-card-text>
          <span v-if="!loading && !listasCotejo.length"
            >No se han generado listas de cotejo</span
          >
          <loadingInstrumentosComponent v-else-if="loading" />
          <listadoInstrumentosComponent
            v-else
            :tipo="tipo"
            :instrumentos="listasCotejoFiltradas"
            :areas="areasListasCotejo"
            :area="areaSeleccionada"
            @instrumentoModificado="instrumentoModificado"
            @listaCotejoClonada="listaCotejoClonada"
          />
        </v-card-text>
      </v-container>

      <!-- termina zona de impresion -->
      <editarAreaInstrumentosComponent
        v-if="abrirEditorAreaLista"
        :mostrar="abrirEditorAreaLista"
        :editarAreaInstrumento="editarAreaLista"
        :area="areaParaModificar"
        :tipo="tipo"
        @cancelar="cancelarEditarArea"
        @areaGuardada="areaGuardada"
      />

      <crearInstrumentoDialog
        v-if="crearListaCotejo.mostrar"
        :mostrar="crearListaCotejo.mostrar"
        :tipo="tipo"
        :idAreaInstrumento="crearListaCotejo.id"
        @cancelar="cancelarCreacionInstrumento"
        @listaCotejoGuardada="listaCotejoGuardada"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import loadingInstrumentosComponent from "./loadingInstrumentos.vue";
import selectorAreasComponent from "../widgets/selectorAreas.vue";

import editarAreaInstrumentosComponent from "./areas/editarAreaInstrumentos.vue";

import crearInstrumentoDialog from "./crearInstrumento.vue";
import listadoInstrumentosComponent from "./listaInstrumentos.vue";

import { getListasCotejoByAreasService } from "./instrumentos.service";
import { getAreasInstrumentosService } from "./areas/areas.service";
import { permisoMenuEscuela } from '../../helpers/utilerias';

export default {
  name: "instrumentosLista",
  props: {
    tipo: { type: String, enum: ["listaCotejo", "rubrica"] },
    nuevaListaCotejo: { type: Object, default: () => {} },
  },
  components: {
    loadingInstrumentosComponent,
    selectorAreasComponent,
    crearInstrumentoDialog,
    listadoInstrumentosComponent,

    editarAreaInstrumentosComponent,
  },
  computed: {
    ...mapGetters([
      "listaCotejoIcon",
      "agregarContenidoIcon",
      "sessionToken",
      "role",
      "app",
    ]),
    esAdmin() {
      return this.app === "campus" || this.role == "admin" || this.role === "master";
    },
    listasCotejoFiltradas() {
      if (this.search)
        return this.listasCotejo.filter((l) =>
          l.nombre.toUpperCase().includes(this.search.toUpperCase())
        );
      return this.listasCotejo;
    },
  },
  watch: {
    sessionToken() {
      if (!permisoMenuEscuela("Instrumentos")) this.$router.push("/dashboard");
      this.getAreaListaCotejo();
    },
    areaSeleccionada() {
      this.getListasCotejo();
    },
    nuevaListaCotejo(value) {
      if (value) {
        this.getAreaListaCotejo();
      }
    },
  },
  mounted() {
    if (!this.$route.hash && this.app === "campus")
      this.$router.push("#listas");
    if (this.sessionToken) {
      if (!permisoMenuEscuela("Instrumentos")) this.$router.push("/dashboard");
      this.getAreaListaCotejo();
    }
  },
  data() {
    return {
      loading: true,
      search: "",
      listasCotejo: [],
      areasListasCotejo: [],
      areaSeleccionada: "",
      crearListaCotejo: {
        mostrar: false,
        id: null,
      },
      abrirEditorAreaLista: false,
      editarAreaLista: false,
      areaParaModificar: null,
    };
  },
  methods: {
    // Áreas Lista Cotejo
    modificarAreaSeleccionada() {
      let area = this.areasListasCotejo.find(
        (x) => x._id == this.areaSeleccionada
      );
      this.areaParaModificar = area;
      this.abrirEditorAreaLista = true;
      this.editarAreaLista = true;
    },
    areaGuardada(nuevaArea) {
      const areaExiste = this.areasListasCotejo.filter(
        (e) => e._id === nuevaArea._id
      )[0];

      if (areaExiste) {
        let index = this.areasListasCotejo.findIndex(
          (x) => x._id === nuevaArea._id
        );
        this.areasListasCotejo.splice(index, 1, nuevaArea);
      } else {
        this.areasListasCotejo.push(nuevaArea);
        this.areaSeleccionada = nuevaArea._id;
        this.getListasCotejo();
      }

      this.abrirEditorAreaLista = false;
      this.editarAreaLista = false;
      this.areaParaModificar = null;
    },
    cancelarEditarArea() {
      this.abrirEditorAreaLista = false;
      this.editarAreaLista = false;
      this.areaParaModificar = null;
    },
    // COMPLETADO
    async getAreaListaCotejo() {
      try {
        this.loading = true;
        const serverResponse = await getAreasInstrumentosService(this.tipo);
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.areasListasCotejo = serverResponse.areasInstrumentos;
          if (this.areasListasCotejo.length) {
            this.areaSeleccionada = serverResponse.areasInstrumentos[0]._id;
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async getListasCotejo() {
      try {
        this.loading = true;
        const serverResponse = await getListasCotejoByAreasService(this.areaSeleccionada);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.listasCotejo = serverResponse.listasCotejo;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    // -----------------------
    // Listas Cotejo
    abrirCreacionInstrumento() {
      this.crearListaCotejo.mostrar = true;
      this.crearListaCotejo.id = this.areaSeleccionada;
    },
    listaCotejoGuardada(listaCotejo) {
      this.listasCotejo.push(listaCotejo);
      this.crearListaCotejo.mostrar = false;
    },
    cancelarCreacionInstrumento() {
      this.crearListaCotejo.mostrar = false;
      this.crearListaCotejo.id = null;
    },
    instrumentoModificado(instrumento) {
      let indexInstrumento = this.listasCotejo.findIndex(
        (x) => x._id == instrumento._id
      );

      this.listasCotejo.splice(indexInstrumento, 1);
    },
    listaCotejoClonada() {
      this.getAreaListaCotejo();
    },
  },
};
</script>

<style></style>
