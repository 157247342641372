<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card :loading="loading">
      <v-toolbar dense color="primary" dark flat class="text-h6">
        Clonar instrumento
      </v-toolbar>
      <v-card-text class="pt-3 pb-0 px-4">
        <span
          >Selecciona el área donde donde quedará guardada la copia del
          instrumento:</span
        >
      </v-card-text>
      <v-container style="margin-top: 10px">
        <v-select
          v-model="areaSeleccionada"
          label="Área"
          :items="areas"
          item-value="_id"
          item-text="nombre"
          outlined
          dense
          :disabled="loading"
          no-data-text="No hay áreas disponibles"
        >
        </v-select>
      </v-container>
      <v-spacer></v-spacer>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :class="{
            'disable-events':
              loading || areaSeleccionada == '',
          }"
          :loading="loading"
          @click="submit()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { clonarListaCotejoService, clonarRubricaService } from "./instrumentos.service";

export default {
  name: "clonarInstrumento",
  props: {
    mostrar: { type: Boolean, default: false },
    instrumento: { type: Object, default: () => null },
    areas: { type: Array, default: () => [] },
    tipo: { type: String, enum: ["listaCotejo", "rubrica"] },
  },

  data: () => ({
    loading: false,
    areaSeleccionada: "",
  }),

  watch: {
    areas() {
      this.setAreaSeleccionada();
    },
  },

  mounted() {
    this.setAreaSeleccionada();
  },

  methods: {
    setAreaSeleccionada() {
      if (this.instrumento && this.areas.length && this.instrumento.instrumentoArea) {
        this.areaSeleccionada = this.areas.filter((e) => e._id === this.instrumento.instrumentoArea[0]._id || this.instrumento.instrumentoArea)[0]._id;
      } else if (this.instrumento && this.areas.length) {
        this.areaSeleccionada = this.areas[0]._id;
      }
    },
    async submit() {
      try {
        this.loading = true;
        let serverResponse = null;

        const data = {
          idArea: this.areaSeleccionada,
        };

        if (this.tipo == "listaCotejo")
          serverResponse = await clonarListaCotejoService(
            this.instrumento._id,
            data
          );
        if (this.tipo == "rubrica")
          serverResponse = await clonarRubricaService(
            this.instrumento._id,
            data
          );

        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = { tipo: this.tipo, instrumento: serverResponse.listaCotejo || serverResponse.rubrica}
          this.$emit("instrumentoClonado", emitData);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>