<template>
  <v-card flat>
    <v-skeleton-loader type="list-item-avatar-two-line@5"></v-skeleton-loader>
  </v-card>
</template>

<script>
export default {
  name: "loadingInstrumentos",
};
</script>
