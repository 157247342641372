<template>
  <v-list>
    <v-list-item v-for="instrumento in instrumentos" :key="instrumento._id">
      <v-list-item-avatar>
        <v-avatar color="primary">
          <v-icon v-if="tipo == 'listaCotejo'" dark>{{listaCotejoIcon}}</v-icon>
          <v-icon v-if="tipo == 'rubrica'" dark>{{ rubricaIcon }}</v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <router-link
            :to="`instrumentos/${tipo}/${instrumento._id}`"
            class="link"
            >{{ instrumento.nombre }}
          </router-link>
        </v-list-item-title>
        <v-list-item-subtitle>
          <instrumentoStatusComponent :status="instrumento.status" />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="esAdmin">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(menuInstrumentoItem, index) in menuInstrumento"
              :key="index"
              @click="
                menuAction({
                  ...menuInstrumentoItem,
                  id: instrumento._id,
                })
              "
            >
              <v-list-item-title>
                <v-icon small class="mr-2">{{
                  menuInstrumentoItem.icon
                }}</v-icon>
                <span>{{ menuInstrumentoItem.text }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>

    <moverAreaComponent
      v-if="moverInstrumento.mostrar"
      :mostrar="moverInstrumento.mostrar"
      :areas="moverInstrumento.areas"
      :idInstrumento="moverInstrumento.idInstrumento"
      :tipo="tipo"
      @cancelar="cerrarMoverArea"
      @instrumentoModificado="instrumentoModificado"
    />

    <clonarInstrumentoComponent
      v-if="mostrarClonarInstrumento.mostrar"
      :mostrar="mostrarClonarInstrumento.mostrar"
      :instrumento="mostrarClonarInstrumento.instrumento"
      :areas="areas"
      :tipo="tipo"
      @cancelar="cerrarClonarInstrumento"
      @instrumentoClonado="instrumentoClonado"
    />
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
import instrumentoStatusComponent from "./instrumentoStatus.vue";
import moverAreaComponent from "./moverInstrumentoArea.vue";
import clonarInstrumentoComponent from "./clonarInstrumento.vue";

export default {
  name: "listadoInstrumentos",
  props: {
    instrumentos: { type: Array, default: () => [] },
    areas: { type: Array, default: () => [] },
    area: { type: String, default: "" },
    tipo: { type: String, enum: ["listaCotejo", "rubrica"] },
  },

  components: {
    instrumentoStatusComponent,
    moverAreaComponent,
    clonarInstrumentoComponent,
  },
  computed: {
    ...mapGetters([
      "listaCotejoIcon",
      "rubricaIcon",
      "agregarContenidoIcon",
      "sessionToken",
      "app",
      "role"
    ]),
    esAdmin() {
      return this.app === "campus" || this.role === "admin";
    },
  },
  data() {
    return {
      loading: false,
      moverInstrumento: {
        mostrar: false,
        areas: [],
        idInstrumento: null,
      },
      mostrarClonarInstrumento: { mostrar: false, instrumento: null },
      menuInstrumento: [
        {
          text: "Mover de área",
          action: "moverDeArea",
          icon: "mdi-subdirectory-arrow-left",
        },
        {
          text: "Duplicar Instrumento",
          action: "clonarInstrumento",
          icon: "mdi-content-copy",
        },
      ],
    };
  },
  methods: {
    menuAction(data) {
      switch (data.action) {
        case "moverDeArea":
          this.moverInstrumentoArea(data.id);
          break;
        case "clonarInstrumento":
          this.clonarInstrumento(data.id);
          break;
      }
    },
    moverInstrumentoArea(idInstrumento) {
      this.moverInstrumento.idInstrumento = idInstrumento;
      this.moverInstrumento.areas = this.areas.filter(
        (x) => x._id !== this.area
      );

      this.moverInstrumento.mostrar = true;
    },
    cerrarMoverArea() {
      this.mostrarMoverArea = false;
      this.moverInstrumento = {
        mostrar: false,
        areas: [],
        idInstrumento: null,
      };
    },
    clonarInstrumento(idInstrumento) {
      const indexInstrumento = this.instrumentos.findIndex(
        (x) => x._id === idInstrumento
      );

      this.mostrarClonarInstrumento.instrumento =
        this.instrumentos[indexInstrumento];
      this.mostrarClonarInstrumento.mostrar = true;
    },
    cerrarClonarInstrumento() {
      this.mostrarClonarInstrumento.mostrar = false;
      this.mostrarClonarInstrumento.instrumento = null;
    },
    instrumentoModificado(instrumento) {
      this.moverInstrumento.mostrar = false;
      this.moverInstrumento.idInstrumento = null;
      this.$emit("instrumentoModificado", instrumento);
    },
    instrumentoClonado(instrumento) {
      if (instrumento.tipo === 'listaCotejo') this.$emit("listaCotejoClonada", instrumento.instrumento);
      else this.$emit("rubricaClonada", instrumento.instrumento);
    },
  },
};
</script>

