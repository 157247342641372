import { postServerRequest, patchServerRequest, getServerRequest } from "../../../services/serverRequest.service";
import { store } from '../../../../../store/store';

const httpURL = store.getters.httpURL;

export const getAreasInstrumentosService = async (tipo) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/instrumentos/areas/${tipo}`;
    return await getServerRequest(url, config);
};

export const crearAreaInstrumentoService = async (data, tipo) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/instrumentos/areas/${tipo}/crear`;
    return await postServerRequest(url, data, config);
}

export const modificarAreaInstrumentoService = async (idArea, data, tipo) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/instrumentos/areas/${tipo}/${idArea}`;
    return await patchServerRequest(url, data, config);
}
