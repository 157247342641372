<template>
  <v-container>
    <v-card flat>
      <v-container>
        <v-row>
          <v-col md="6" sm="12">
            <v-container>
                <v-select
                    :items="tiposInstrumentos"
                    v-model="tipoSeleccionado"
                    item-text="nombre"
                    item-value="tipo"
                    label="Tipo:"
                    outlined
                    hide-details
                    dense
                    @change="getInstrumento()"
                ></v-select>
            </v-container>
          </v-col>
          <v-col md="6" sm="12">
            <v-container>
              <div class="d-flex">
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  append-icon="mdi-magnify"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </div>
            </v-container>
          </v-col>
        </v-row>

        <v-divider class="mx-3"></v-divider>
        <v-card-text>
          <span v-if="!loading && !instrumentos.length"
            >No se cuenta con instrumentos compartidos.</span
          >
          <loadingInstrumentosComponent v-else-if="loading" />
          <listadoInstrumentosCompartidosComponent 
            v-else
            :tipo="tipoSeleccionado"
            :instrumentos="instrumentosFiltrados"
            :areas="areasInstrumentos"
            @instrumentoClonado="instrumentoClonado"
          />

        </v-card-text>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import loadingInstrumentosComponent from "./loadingInstrumentos.vue";
import listadoInstrumentosCompartidosComponent from "./listaCompartidos.vue";

import { getInstrumentosCompartidos } from "./instrumentos.service";
export default {
  name: "instrumentosCompartidos",
  components: {
    loadingInstrumentosComponent,
    listadoInstrumentosCompartidosComponent,
  },
  watch: {
    sessionToken(value) {
      if (value) {
        this.getInstrumento();
      }
    },
  },
  computed: {
    ...mapGetters([
      "listaCotejoIcon",
      "agregarContenidoIcon",
      "sessionToken",
      "role",
      "app",
    ]),
    instrumentosFiltrados(){
      if(this.search)
        return this.instrumentos.filter((i) =>
          i.nombre.toUpperCase().includes(this.search.toUpperCase())
        );
        return this.instrumentos;
    }
  },
  async mounted() {
    this.tipoSeleccionado = this.tiposInstrumentos[0].tipo;
    if (this.sessionToken) {
      this.getInstrumento();
    }
  },
  data() {
    return {
      loading: true,
      search: "",
      tiposInstrumentos: [
        { nombre: 'Listas cotejo', tipo: 'listaCotejo' },
        { nombre: 'Rubricas', tipo: 'rubrica' }],
      tipoSeleccionado: null,
      instrumentos: [],
      areasInstrumentos: [],
    };
  },
  methods: {
    async getInstrumento(){
      try {
        this.instrumentos = [];
        this.loading = true;

        const serverResponse = await getInstrumentosCompartidos(this.tipoSeleccionado);
      
        this.loading = false;
        if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.instrumentos = serverResponse.listaInstrumentos;
          this.areasInstrumentos = serverResponse.areas;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    instrumentoClonado(instrumento) {
      this.$emit("instrumentoClonado", instrumento, this.tipoSeleccionado);
    },
  }
};
</script>