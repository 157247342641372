import { postServerRequest, patchServerRequest, getServerRequest, putServerRequest } from "../../services/serverRequest.service";
import { store } from '../../../../store/store';

const httpURL = store.getters.httpURL;

// Listas Cotejo

export const getListasCotejoByAreasService = async (idAreaInstrumento) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/instrumentos/listaCotejo/${idAreaInstrumento}`;
    return await getServerRequest(url, config);
}

export const crearListaCotejoService = async (idArea, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/instrumentos/listaCotejo/crear/${idArea}`;
    return await putServerRequest(url, data, config);
}

export const moverListaCotejoAreaService = async (data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/instrumentos/listaCotejo/mover/${data.idInstrumento}/${data.idArea}`;
    return await patchServerRequest(url, {}, config);
}

export const clonarListaCotejoService = async (idInstrumento, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/instrumentos/listaCotejo/clonar/${idInstrumento}`;
    return await patchServerRequest(url, data, config);
}

// Rubricas

export const getRubricasByAreasService = async (idAreaInstrumento) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/instrumentos/rubrica/${idAreaInstrumento}`;
    return await getServerRequest(url, config);
}

export const crearRubricaService = async (idArea, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/instrumentos/rubrica/crear/${idArea}`;
    return await putServerRequest(url, data, config);
}

export const moverRubricaAreaService = async (data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/instrumentos/rubrica/mover/${data.idInstrumento}/${data.idArea}`;
    return await patchServerRequest(url, {}, config);
}

export const clonarRubricaService = async (idInstrumento, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/instrumentos/rubrica/clonar/${idInstrumento}`;
    return await patchServerRequest(url, data, config);
}

// Compartidos
export const getInstrumentosCompartidos = async (tipo) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/instrumentos/compartidos/${tipo}`;
    return await getServerRequest(url, config);
}
